import { makeStyles } from '@material-ui/core';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Colors from '../styles/colors';
import Dimensions, { narrowWidth } from '../styles/dimensions';
import content from '../content/agb/page.json';

const Agb = () => {
	const classes = useClasses();

	return (
		<Layout>
			<SEO title={content.title} />

			<div className={classes.page}>
				<div className={classes.container}>
					<h1 className={classes.header}>{content.title}</h1>
					<p className={classes.subtitle}>{content.subtitle}</p>

					{content.listItems.map((item, key) => (
						<React.Fragment key={key}>
							<h4 className={classes.subheader}>{item.title}</h4>
							{item.listItems.map((innerItem, i) => (
								<p key={i}>{innerItem}</p>
							))}
						</React.Fragment>
					))}
				</div>
			</div>
		</Layout>
	);
};

export default Agb;

const useClasses = makeStyles({
	page: {
		backgroundColor: Colors.offerBackgroundBlack,
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		paddingTop: '3.5em',
		paddingBottom: '5.75em',
		paddingLeft: '0.8em',
		paddingRight: '0.8em',
		whiteSpace: 'pre-wrap',
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		'& a': {
			textDecoration: 'underline',
		},
        '& p': {
            margin: '2em 0',
        },
	},
	container: {
		maxWidth: narrowWidth,
		margin: '0 auto',
	},
	header: {
		fontSize: Dimensions.largeTitle.fontSize,
		lineHeight: Dimensions.largeTitle.lineHeight,
		color: Colors.white,
		marginTop: 0,
		marginBottom: '0.5em',
		letterSpacing: '0.03571428571428571em',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.regularTitle.fontSize,
			lineHeight: Dimensions.regularTitle.lineHeight,
		},
	},
    subheader: {
		color: Colors.white,
        letterSpacing: '0.1em',
		marginTop: '1.8333333333333333em',
		marginBottom: '0.5em',
	},
    subtitle: {
        marginTop: '1.4em',
        marginBottom: '2em',
    },
});
